import React from "react"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Mission from "../components/mission"
import Hero from "../components/hero"
import { HeaderStyle } from "../components/styles/main"
const ExpStyled = styled.div`
  padding: 1rem 2rem;
`
const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <Hero src="about-header.jpg" alt="Header about us" />
    <ExpStyled>
      <HeaderStyle>Our Experience</HeaderStyle>
      <p>
        The Human Resources Potential Center (HRPC) was founded by Stephen P.
        Denehan B.A., M.S., J.D., a highly experienced human resources
        professional with over 30 years of executive experience at prestigious
        global corporations such as Pan American World Airways, AMR Services
        (American Airlines), El Al Israel Airlines and more recently, with
        Emirates Airlines.
      </p>

      <p>
        During his career, Mr. Denehan specialized in recruitment, career
        counseling, and improving employee performance. He has undergone highly
        sophisticated training in the USA, England, and the U.A.E. that has
        familiarized him with the latest and most powerful personality and
        aptitude assessments, education/occupation interest profiles, knowledge
        and skills testing. As a result of this training, Mr. Denehan is
        qualified to use the advanced tools offered by companies such as CPP
        Inc., Cubiks, ipat and cut-e.
      </p>

      <p>
        The HRPC builds upon his well-established reputation for the creation
        and development of highly successful human resources and administrative
        platforms, offering guidance to corporate clients seeking to tap the
        potential of employees as well as to individuals deciding what to focus
        on in school or who hope to enhance their career potential.{" "}
      </p>
    </ExpStyled>
    <hr />
    <Mission />
  </Layout>
)

export default AboutPage
